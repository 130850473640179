import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let existingAbility = []

try {
  const data = JSON.parse(localStorage.getItem('auth'))

  if (data.role === 'vendor') {
    existingAbility = [{ action: 'manage', subject:'dashboard'}, { action: 'manage', subject: 'campaigns-professional'}, { action: 'manage', subject: 'profile'}]
  } else {
    existingAbility = [{ action: 'manage', subject:'dashboard'}, { action: 'manage', subject: 'campaigns'}, { action: 'manage', subject: 'profile'}]
  }
} catch (e) {
  existingAbility = [{ action: 'manage', subject:'dashboard'}, { action: 'manage', subject: 'campaigns'}, { action: 'manage', subject: 'profile'}]
}

export default new Ability(existingAbility || initialAbility)
